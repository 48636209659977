<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">账号管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">账号列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="账号名称" class="searchboxItem ci-full">
              <span class="itemLabel">账号名称:</span>
              <el-input
                v-model="adminName"
                type="text"
                size="small"
                clearable
                placeholder="请输入账号名称"
              />
            </div>
            <div title="使用人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">使用人姓名:</span>
              <el-input
                v-model="fullname"
                type="text"
                size="small"
                clearable
                placeholder="请输入使用人姓名"
              />
            </div>
            <div title="使用人电话" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">使用人电话:</span>
              <el-input
                v-model="mobile"
                type="text"
                size="small"
                clearable
                placeholder="请输入使用人电话"
              />
            </div>
            <div title="角色名称" class="searchboxItem ci-full">
              <span class="itemLabel">角色名称:</span>
              <el-input
                v-model="realRoleName"
                type="text"
                clearable
                size="small"
                placeholder="请输入角色名称"
              />
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >创建账号</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="账号名称"
                align="left"
                prop="adminName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="使用人姓名"
                align="left"
                prop="fullname"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="使用人电话"
                align="left"
                prop="mobile"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="角色"
                align="left"
                prop="realRoleName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column label="状态" align="center" min-width="100">
                <template slot-scope="scope">
                  {{ scope.row.state == 10 ? "启用" : "禁用" }}
                  <span></span>
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    @click="handleCreate('edit', scope.row.adminId)"
                    >编辑</el-button
                  >
                  <!-- 隐藏删除按钮 <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleDel(scope.row.adminId)"
                  >删除</el-button>-->
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    @click="handlePassword(scope.row.adminId)"
                    >重置密码</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="重置密码"
      :visible.sync="DialogVisible"
      width="30%"
      center
    >
      <div class="flexdcc">
        <p>是否将用户密码重置？</p>
        <p>重置后，变为初始密码123456</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="DialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="doPassword" class="bgc-bv"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="agentAccounttTitle"
      :visible.sync="agentAccounttVisible"
      width="50%"
      center
      @close="cancle"
    >
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="110px"
          class="demo-ruleForm"
        >
          <el-form-item label="账号名称：" prop="accounttName">
            <el-input v-model="ruleForm.accounttName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="使用人姓名：" prop="userName">
            <el-input v-model="ruleForm.userName" size="small"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" prop="userPhone">
            <el-input v-model="ruleForm.userPhone" size="small"></el-input>
          </el-form-item>
          <el-form-item label="角色：" prop="roleId">
            <el-select
              clearable
              v-model="ruleForm.roleId"
              placeholder="请选择角色"
              size="small"
              :disabled="ruleForm.oneself"
            >
              <el-option
                v-for="item in roleList"
                :key="item.id"
                :label="item.authority"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="关联代理商" prop="agencyId">
            <el-select
              clearable
              v-model="ruleForm.agencyId"
              filterable
              size="small"
              remote
              :disabled="true"
              :remote-method="searchAgency"
              placeholder="请输入关键字搜索"
            >
              <el-option
                size="small"
                v-for="item in agencyList"
                :key="item.agencyId"
                :label="
                  item.agencyType != '20'
                    ? item.agencyName + '(' + item.agencyMobile + ')'
                    : item.agencyName
                "
                :value="item.agencyId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号权限：" prop="permissionsId">
            <el-select
              clearable
              v-model="ruleForm.permissionsId"
              placeholder="请选择账号权限"
              size="small"
            >
              <el-option
                v-for="item in permissionsList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="数据类型：" prop="trainTypeId">
            <el-cascader
              placeholder="请选择数据类型"
              v-model="ruleForm.trainTypeId"
              :options="trainTypeList"
              :props="propsTrainType"
              size="small"
            ></el-cascader>
          </el-form-item> -->
          <el-form-item label="启用状态：" prop="enableDisableState">
            <el-select
              clearable
              v-model="ruleForm.enableDisableState"
              placeholder="请选择启用状态"
              size="small"
            >
              <el-option
                size="small"
                v-for="item in enableDisableStateList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
           <el-form-item
                  label="创建账号"
                  prop="allowNew"
                >
                  <el-checkbox
                    v-model="ruleForm.allowNew"
                    @change="checkboxchangeallowNew"
                    >代理商允许新建账号</el-checkbox
                  >
                </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="doAddSave('ruleForm')" class="bgc-bv"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      adminName: "",
      fullname: "",
      mobile: "",
      realRoleName:'',
      DialogVisible: false,
      agentAccounttTitle: "创建账号",
      agentAccounttVisible: false,
      /* 数据权限 --下拉数据 */
      permissionsList: [
        {
          value: 10,
          label: "仅本人",
        },
        {
          value: 40,
          label: "本人及所建账号",
        },
      ],
      /* 状态 --下拉数据 */
      enableDisableStateList: [
        {
          value: '10',
          label: "启用",
        },
        {
          value: '20',
          label: "禁用",
        },
      ],
      ruleForm: {
        accounttName: "",
        userName: "",
        userPhone: "",
        permissionsId: "",
        enableDisableState: "",
        // trainTypeId: "",
        agencyId:'',
        roleId:'',
        oneself: false,
        allowNew:false
      },
      trainTypeList: [],
      roleList:[],
      agencyList:[],
      propsTrainType: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: true,
        multiple: true,
      },
      rules: {
        accounttName: [
          { required: true, trigger: "blur", message: "账号名称不能为空" },
          { trigger: "blur", max: 20, message: "账号名称不超过20个字" },
        ],
        userName: [
          { required: true, trigger: "blur", message: "使用人姓名不能为空" },
          { trigger: "blur", max: 20, message: "使用人姓名不超过20个字" },
        ],
        userPhone: [
          {
            required: true,
            validator: this.$validatePhone,
            trigger: "blur",
          },
        ],
        roleId: [
          { required: true, trigger: "change", message: "请选择角色" },
        ],
        permissionsId: [
          { required: true, trigger: "change", message: "请选择账号权限" },
        ],
        // trainTypeId: [
        //   { required: true, trigger: "change", message: "请选择数据类型" },
        // ],
        enableDisableState: [
          { required: true, trigger: "change", message: "请选择启用状态" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  created() {
    this.getTableHeight();
    this.getTrainTypeList();
    
     this.ruleForm.agencyId = this.userJson.compId;
    this.getAgencyL(this.userJson.compId)
  },
  methods: {
    /**
     * tree接口
     */
    getTrainTypeList() {
      this.$post("/agency/ma-agency-student/getAreaAndTrain")
        .then((res) => {
          this.trainTypeList = res.data.trainTypeList;
        })
        .catch(() => {
          return;
        });
    },
    //角色
    getRoleList() {
      this.$post("/agency/account/role-list")
        .then((res) => {
          this.roleList = res.data;
        })
        .catch(() => {
          return;
        });
    },
      getAgencyL(agencyId) {
      this.$post("/biz/mercantile/agency/searchList", { agencyId }).then(
        (res) => {
          this.agencyList = res.data || [];
        }
      );
    },
    roletype(id) {
      // switch (id) {
      //   case "1":
      //     return "运营";
      //   case "2":
      //     return "监管";
      //   case "3":
      //     return "机构";
      //   case "-1":
      //     return "市场";
      //   default:
      //     return "";
      // }
      return this.roleType.find((item) => item.id == id).name;
    },
    roleChange(val) {
      console.log(val);
      if (val == "-3") {
        this.compType = "2";
      } else if (val == "3") {
        this.compType = "1";
      } else {
        this.compType = "";
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.adminName) {
        params.adminName = this.adminName;
      }

      if (this.compType) {
        params.compType = this.compType;
      }
      if (this.fullname) {
        params.fullname = this.fullname;
      }
      if (this.mobile) {
        params.mobile = this.mobile;
      }
      if (this.realRoleName) {
        params.realRoleName = this.realRoleName;
      }
      if (this.roleId) {
        params.roleId = this.roleId;
      }
      if (this.state) {
        params.state = this.state;
      }
      this.getrole(() => {
        this.doFetch({
          url: "/agency/account/pageList",
          params,
          pageNum,
        });
      });
    },
    getrole(callback) {
      this.$post("/sys/per/role/basal")
        .then((res) => {
          this.roleType = res.data || [];
          callback();
        })
        .catch(() => {
          return;
        });
    },
    // 新增--修改
    handleCreate(stu, adminId = "") {
      this.agentAccounttVisible = true;
      this.stu = stu;
      this.adminId = adminId
      this.getRoleList();
      if (stu == "add") {
        this.agentAccounttTitle = "创建账号";
      } else {
        this.agentAccounttTitle = "修改账号";
        this.getDetail(adminId);
      }
    },
     /**
     * 编辑回显
     */
    getDetail(adminId) {
      this.$post("/sys/admin/adminId", { adminId}).then((res) => {
        this.ruleForm ={
          accounttName: res.data.adminName,
          userName: res.data.fullname,
          userPhone: res.data.mobile,
          roleId:res.data.realRoleId,
          permissionsId: res.data.authorizationScope,
          enableDisableState: res.data.state,
          // trainTypeId: res.data.trainTypeIdRange.split(","),
          agencyId:res.data.compId,
          oneself: res.data.oneself,
          allowNew:res.data.allowNew
        }
        if (this.ruleForm.oneself) {
          this.roleList.push({id: "34", authority: "代理商管理员"});
        }
      });
    },
 /**
     * 新增
     */
    doAddSave(formName) {
      const params = {
        adminName: this.ruleForm.accounttName,
        fullname: this.ruleForm.userName,
        mobile: this.ruleForm.userPhone,
        realRoleId:this.ruleForm.roleId,
        authorizationScope:this.ruleForm.permissionsId,
        state:this.ruleForm.enableDisableState,
        allowNew: this.ruleForm.allowNew,
        // trainTypeIdRange:this.ruleForm.trainTypeId.toString()
      };
      if (this.adminId) {
        params.adminId = this.adminId;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$post(
            this.stu != "edit"
              ? "/agency/account/insert"
              : "/agency/account/modify",
            params
          )
            .then((res) => {
              if (res.status == "0") {
              this.cancle()
                this.getData()
              }
            })
            .catch(() => {
              return;
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /* 取消弹框 */
    cancle() {
      this.ruleForm.oneself = false;
      this.agentAccounttVisible = false;
      this.$refs["ruleForm"].resetFields();
      let idx = this.roleList.findIndex(o => o.id == 34);
      if (idx != -1) {
        this.roleList.splice(idx, 1);
      }
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    // 重置密码
    handlePassword(id) {
      this.DialogVisible = true;
      this.adminId = id;
    },
    doPassword() {
      this.$post("/agency/account/restPassword", { adminId: this.adminId }).then(
        (res) => {
          if (res.status == "0") {
            this.$message({
              type: "success",
              message: "密码重置成功",
            });
            this.DialogVisible = false;
            this.getData(-1);
          }
        }
      );
    },

    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
    checkboxchangeallowNew(val) {
      this.ruleForm.allowNew = val;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.adminName = ""),
            (this.compName = ""),
            (this.fullname = ""),
            (this.mobile = ""),
            (this.realRoleName = ""),
            (this.roleId = ""),
            (this.state = ""),
           
            (this.pageNum = 1),
            this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped>
 /deep/.el-input-group__prepend {
  width: 6rem;
}
</style>
